(function($) {
    /**
     *
     * Variables
     *
     */
    var $markersAll = [];
    // add a markers reference
    var map;
    var mapMarkers = [];
    var lastOpenedInfoWindow;

    /*
    *  new_map
    *  This function will render a Google Map onto the selected jQuery element
    */
    function new_map( $el ) {
        // var
        var $markers = $('body').find('.map-marker');
        // vars
        var args = {
            zoom        : 14,
            center      : new google.maps.LatLng(0, 0),
            mapTypeId   : google.maps.MapTypeId.ROADMAP
        };
        // create map
        map = new google.maps.Map( $el[0], args);

        // add markers
        $markers.each(function(){
            add_marker( $(this), map );
            $markersAll.push( $(this) );
        });
        // center map
        center_map( map );
    }



    /*
    *  add_marker
    *  This function will add a marker to the selected Google Map
    */
    function add_marker( $marker, map ) {
        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
        // create marker
        if($marker.attr('data-pin')) {
            var iconImage = $marker.attr('data-pin');
        } else {
            var iconImage = $marker.attr('data-pin');
        }
        var image = {
            url: iconImage,
            size:  new google.maps.Size(30,38),
            scaledSize: new google.maps.Size(30,38),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15,38)
        };
        var marker = new google.maps.Marker({
            position    : latlng,
            map         : map,
            animation: google.maps.Animation.DROP,
            icon: image,
        });
        // add to array
        mapMarkers.push( marker );
        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() )
        {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content     : '<article class="post-search-result post-search-result--marker">'+$marker.html()+'</article>'
            });
            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
                closeLastOpenedInfoWindow();
                infowindow.open( map, marker );
                lastOpenedInfoWindow = infowindow;
            });
        }
    }

    function closeLastOpenedInfoWindow() {
        if (lastOpenedInfoWindow) {
            lastOpenedInfoWindow.close();
        }
    }



    /*
    *  center_map
    *  This function will center the map, showing all markers attached to this map
    */
    function center_map(  ) {
        // vars
        var bounds = new google.maps.LatLngBounds();
        // loop through all markers and create bounds
        $.each( mapMarkers, function( i, marker ){
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
        });

        if( mapMarkers.length == 1 )
        {
            // set center of map
            map.setCenter( bounds.getCenter() );
            map.setZoom( 14 );
        }
        else
        {
            // fit to bounds
            map.fitBounds( bounds );
        }
    }


    /**
     *
     * Clear locatioms
     *
     */
    function clearLocations() {
        // clear markers
        for (var i = 0; i < mapMarkers.length; i++) {
            mapMarkers[i].setMap(null);
        }
        mapMarkers.length = 0;

        $('.map-marker').addClass('isHidden');
    }


    /**
     *
     * Search Location
     *
     */
    function searchLocation(address, radius) {
        //var address = document.getElementById("addressInput").value;
        var geocoder = new google.maps.Geocoder();
        var request = {
            address: address,
            componentRestrictions: {
                country: 'UK'
            }
        }
        geocoder.geocode(request, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                searchLocationsNear(results[0].geometry.location, radius);
            } else {
                console.log(address + ' not found');
            }
        });
    }



    /**
     *
     * Search near locations
     *
     */

    function searchLocationsNear(center, radius) {
        // clear current markers
        $('#searchResultsList').fadeOut(250);
        $('#searchResultsListEmpty').addClass('hidden');

        var marker_total = $markersAll.length;
        var result_total = 0;
        var count = 1;

        setTimeout(function() {

            clearLocations();

            $('#searchResultsList').fadeIn(250);

            // loop all markers
            $.each($markersAll, function() {
                var $this = this;

                // lat lng of search
                var marker_lat_lng = new google.maps.LatLng( $this.attr('data-lat'), $this.attr('data-lng') );

                 // distance in meters between your location and the marker
                var distance_from_location = google.maps.geometry.spherical.computeDistanceBetween(center, marker_lat_lng);

                if (distance_from_location <= radius * 1609.34) {
                    add_marker( $this, map );
                    $this.removeClass('isHidden');
                    $this.parent().attr('data-position', Math.round(distance_from_location));
                    result_total++;
                }

                if (count === marker_total && result_total === 0) {
                    noListResults();
                }

                if(count === marker_total && result_total > 0) {
                    sortListResults();
                    center_map();
                }

                count++;
            });

        }, 250);

    }


    /**
     *
     * No Results
     *
     */
    function noListResults() {
        $('.map-marker').addClass('isHidden');
        $('#searchResultsListEmpty').removeClass('hidden');
    }

    /**
     *
     * Sort List results
     *
     */
    function sortListResults() {
        $("#searchResultsList > li").sort(sort_li).appendTo('#searchResultsList'); // append again to the list
        // sort function callback
    }
    function sort_li(a, b){
        return (parseInt($(b).attr('data-position'))) < (parseInt($(a).attr('data-position'))) ? 1 : -1;
    }



    /**
     *
     * Get VARS
     *
     */
    function parseQueryString() {
        var parsedParameters = {},
        uriParameters = location.search.substr(1).split('&');
        for (var i = 0; i < uriParameters.length; i++) {
            var parameter = uriParameters[i].split('=');
            parsedParameters[parameter[0]] = decodeURIComponent(parameter[1]);
        }
        return parsedParameters;
    }




    /*
    *  document ready
    *  This function will render each map when the document is ready (page has loaded)
    */
    // global var
    $(document).ready(function() {

        /**
         *
         * Onload show map
         *
         */

        $('#searchMap').each(function(){
            // create map
            new_map( $(this) );
        });



        /**
         *
         * Submit Form
         *
         */
        $('body.search #searchMapForm').on('submit', function(evt){
            evt.preventDefault();
            formObject = new Object;
            var formData = $(this).serializeArray();
            $.each(formData, function(key, value) {
                formObject[value.name] = value.value;
            });
            console.log(formObject.address);
            if(formObject.address) {
                searchLocation(formObject.address, formObject.radius);
            } else {
                new_map( $('#searchMap'));
            }

            var pageUrl = '?address=' + encodeURIComponent(formObject.address) + '&radius=' + encodeURIComponent(formObject.radius);
            window.history.pushState('', '', pageUrl);
        });


        /**
         *
         * Page Search
         *
         */
        var getURLQuery = parseQueryString();
        if(getURLQuery.address) {
            clearLocations();
            searchLocation(getURLQuery.address, getURLQuery.radius);

            $('#searchFormAddress').val(getURLQuery.address.replace(/\+/g, ' '));
            $('#searchFormRadius').val(getURLQuery.radius);
        }

    });
})(jQuery);